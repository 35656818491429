import React from 'react'
import { Link } from 'gatsby'
import logo from './img/IMG_0028.jpg'
import fpk from './img/fpk.png'

import styles from './footer.module.css'

export const Footer = ({ text }) => {
  return (
    <footer className={styles.wrapper}>
      <div className={styles.logo}>
        <img src={logo} style={{ maxWidth: 120 }} alt="" />
        <p style={{ maxWidth: 200, fontWeight: 'bold' }}>
          Крымское республиканское отделение ВООПИиК
        </p>
      </div>
      <div className={styles.menu}>
        <div>
          <ul className={styles.menu_page}>
            <li className={styles.menu_item}>
              <Link to="/about">О нас</Link>
            </li>
            <li className={styles.menu_item}>
              <Link to="/contacts">Контакты</Link>
            </li>
            {/* <li className={styles.menu_item}>Волонтерство</li> */}
            {/* <li className={styles.menu_item}>Мероприятия</li> */}
          </ul>
          <img style={{
            backgroundColor: 'white'
          }} src={fpk} alt="" />
        </div>
      </div>
      <div className={styles.copy}>
        <p>
          Использование всех текстовых материалов без изменений в некоммерческих
          целях разрешается со ссылкой на наш сайт. Все аудиовизуальные
          произведения являются собственностью своих авторов и правообладателей
          и используются только в образовательных и информационных целях.
        </p>
        <p>
          Если вы являетесь собственником того или иного произведения и не
          согласны с его размещением на нашем сайте, пожалуйста, напишите на
          krovoopic@mail.ru
        </p>
        <p>{text}</p>
      </div>
    </footer>
  )
}
