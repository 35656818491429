import React from 'react'
import { Link, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import { Footer } from '../components/footer'
import styled from 'styled-components'
import Svg from './svg/Routes.svg'

const LayoutCatalog = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: end;
  position: relative;
  width: 100%;
  /* max-width: 78rem; */
  margin: 0 auto;
  padding: 30px 1rem;
  /* background: #f2f1e2;
  border-radius: 5px;
  box-shadow: 15px 10px 40px -2px rgba(0, 0, 0, 0.8); */

  .about_wrapper {
    width: 100%;
    /* max-width: 580px; */
  }

  h1 {
    font-family: 'GT Walsheim Pro Bold';
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  .about_images {
    margin-bottom: 10vw;
  }

  /* a:hover {
    opacity: 0.8;
  } */
`

const Card = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  border-radius: 8px;
  height: 200px;
  margin: 30px 0;

  img {
    width: 50%;
  }

  &:hover {
    background-color: #fafafa;
  }

  .first {
    display: flex;
    justify-content: center;
  }
  .second {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    color: #000;
    margin-right: 15px;
  }
  .second-head {
    font-family: 'GT Walsheim Pro Bold';
    padding-bottom: 15px;
  }
`

const PlaceOfMemory = (props) => {
  const posts = props?.data?.allContentfulPlaceOfMemory?.nodes

  return (
    <Layout location="/place-of-memory">
      <LayoutCatalog>
        <Helmet title="Место памяти | Маршруты" />
        <div className="about_wrapper">
          <h1>Маршруты</h1>
          {posts.map(({ slug }) => {
            return (
              <Link to={`/place-of-memory/${slug}`}>
                <Card>
                  <div className="first">
                    <img src={Svg} alt="" />
                  </div>
                  <div className="second">
                    <span className="second-head">{slug}</span>
                  </div>
                </Card>
              </Link>
            )
          })}
        </div>
        <div className="about_images">
          {/* <img src={logo} style={{ maxWidth: 120 }} alt="" /> */}
        </div>
      </LayoutCatalog>
      <Footer
        text="При финансовой поддержке Фонда президентских грантов, проект №
          20-1-032292"
      />
    </Layout>
  )
}

export default PlaceOfMemory

export const pageQuery = graphql`
    query PlaceOfMemory {
      allContentfulPlaceOfMemory {
          nodes {
            link
            slug
          }
        }
    }
`
